import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sendWelcomeEmail } from '../../api';
import { useGlobalState } from '../../hooks';
import Monitoring from '../../utils/monitoring';
import Button from '../Button';

const ButtonResendConfirmationEmail = ({ email }) => {
  const SUCCESS = 'success';
  const ERROR = 'error';
  const LOADING = 'loading';

  const [{ locale }] = useGlobalState();
  const [t] = useTranslation();
  const [state, setState] = useState();
  const [timeLeft, setTimeLeft] = useState(60);

  const sendEmail = async () => {
    try {
      setState(LOADING);
      await sendWelcomeEmail({
        email,
        locale,
      });
      setState(SUCCESS);
    } catch (err) {
      Monitoring.captureException(err, { email, locale });
      setState(ERROR);
    }
  };

  if (timeLeft > 0) {
    setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
  }

  switch (state) {
    case SUCCESS:
      return (
        <Alert severity="success">
          {t('ButtonResendConfirmationEmail.message.success')}
        </Alert>
      );
    case ERROR:
      return (
        <Alert severity="error">
          {t('ButtonResendConfirmationEmail.message.error')}
        </Alert>
      );
    default:
      return (
        <Button
          size="medium"
          variant="light"
          isLoading={state === LOADING}
          onClick={sendEmail}
          disabled={timeLeft > 0}
        >
          {timeLeft > 0
            ? t('ButtonResendConfirmationEmail.button.label.waiting', {
                timeLeft,
              })
            : t('ButtonResendConfirmationEmail.button.label.resend')}
        </Button>
      );
  }
};

ButtonResendConfirmationEmail.propTypes = {
  /** The email address of the user that should be confirmed */
  email: PropTypes.string.isRequired,
};

export default ButtonResendConfirmationEmail;
