import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';

import ArrowSVG from '../../assets/svgs/arrow';
import './Button.scss';

const getBtnSizeClass = (size) => {
  switch (size) {
    case 'big':
      return 'button--big text-button-big';
    case 'medium':
      return 'button--medium text-button-medium';
    case 'small':
      return 'button--small text-button-small';
    default:
      return 'button--big text-button-big';
  }
};

/**
 * Renders a button
 * @param {Object} props
 * @param {Function} props.onClick - The function that is called when a user clicks the button
 * @param {String} props.variant - Sets the variant of the button to be rendered
 * @param {String} props.children - The React nodes that should be rendered within the button.
 * @param {Boolean} props.load - Shows a loading spinner (Deprecated: Use `isLoading` instead.)
 * @param {Boolean} props.isLoading - Shows a loading spinner
 * @param {Boolean} props.disabled - If `true`, the component is disabled.
 * @param {String | false} props.arrow - Sets if an error should be rendered or not.
 * @param {String} props.className - Sets a custom class name to the button
 * @param {Object} restProps - The rest of the props that should be passed to the button
 */
const Button = ({
  children,
  className,
  onClick,
  variant = 'main',
  size = 'big',
  load = false,
  isLoading = false,
  arrow = 'default',
  disabled = false,
  ...restProps
}) => {
  const buttonColor = useMemo(() => {
    switch (variant) {
      case 'main':
        return 'button--main';
      case 'secondary':
        return 'button--secondary';
      case 'main-dark':
        return 'button--main-dark';
      case 'light':
        return 'button--light';
      case 'green':
        return 'button--green';
      case 'red':
        return 'button--red';
      case 'dark-orchid':
        return 'button--dark-orchid';
      default:
        return 'button--main';
    }
  }, [variant]);

  return (
    <button
      onClick={onClick}
      disabled={load || isLoading || disabled}
      className={`button ${getBtnSizeClass(size)} ${className} ${buttonColor}`}
      {...restProps}
    >
      {arrow === 'back' && <ArrowSVG rotate color={buttonColor} />}
      {(load || isLoading) && (
        <div className="spinner-container">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
      <span>{children}</span>
      {arrow === 'default' && <ArrowSVG color={buttonColor} />}
      {arrow === 'down' && (
        <ArrowSVG rotate rotateDeg={90} color={buttonColor} />
      )}
    </button>
  );
};

Button.propTypes = {
  /**
   * The function that is called when a user clicks the button
   */
  onClick: PropTypes.func,

  /**
   * Sets the variant of the button to be rendered
   */
  variant: PropTypes.oneOf([
    'main',
    'secondary',
    'main-dark',
    'light',
    'green',
    'red',
    'dark-orchid',
  ]).isRequired,

  /**
   * The react nodes that should be rendered within the button.
   */
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),

  /** Shows a loading spinner */
  load: PropTypes.bool,

  /** Shows a loading spinner */
  isLoading: PropTypes.bool,

  /** If `true`, the component is disabled. */
  disabled: PropTypes.bool,

  /**
   * Sets if an arrow should be rendered or not.
   */
  arrow: PropTypes.oneOfType([
    PropTypes.oneOf(['default', 'back', 'down', 'none']),
    PropTypes.bool,
  ]),

  /** Sets a custom class name */
  className: PropTypes.string,

  /** Sets the size of the button to be rendered */
  size: PropTypes.oneOf(['small', 'medium', 'big']),
};

export default Button;
